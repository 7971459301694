









import { Component, Prop, Vue } from 'vue-property-decorator';
import { CalendarEvent } from './models';
import DotGroup from './DotGroup.vue';

@Component({
  components: {
    DotGroup,
  },
})
export default class Day extends Vue {
  @Prop({ type: Number, required: true })
  private day!: number;

  @Prop({ type: Boolean, default: false })
  private highlighted!: boolean;

  @Prop({ default: [] })
  private events!: CalendarEvent[];

  // eslint-disable-next-line class-methods-use-this
  private get limit() {
    // eslint-disable-next-line no-restricted-globals
    return screen.width > 525 ? 4 : 3;
  }

  private get checked() {
    return !!this.events.find(({ checked }) => checked);
  }
}
