



















































import {
  Component,
  Emit,
  Prop,
  Vue,
} from 'vue-property-decorator';
import Day from './Day.vue';
import { CalendarEventCollection } from './models';
import Arrow from './Arrow.vue';

@Component({
  components: {
    Arrow,
    Day,
  },
})
export default class DatePicker extends Vue {
  @Prop({ default: {} })
  private collection!: CalendarEventCollection;

  private today: Date = new Date();

  private date: Date = new Date();

  private next() {
    this.date = new Date(this.year, this.month + 1);
  }

  private prev() {
    this.date = new Date(this.year, this.month - 1);
  }

  private get year() {
    return this.date.getFullYear();
  }

  private get month() {
    return this.date.getMonth();
  }

  private get offset() {
    return new Date(this.year, this.month).getDay();
  }

  private get days() {
    return new Date(this.year, this.month + 1, 0).getDate();
  }

  private isToday(day: number): boolean {
    return this.today.getFullYear() === this.year
      && this.today.getMonth() === this.month
      && this.today.getDate() === day;
  }

  @Emit('input')
  private onClick(day: number) {
    return {
      year: this.year,
      month: this.month,
      day,
    };
  }
}
