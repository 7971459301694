


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Day extends Vue {
  @Prop({ type: Boolean, default: false })
  private inverse!: number;
}
