













import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ShareTextButton extends Vue {
  @Prop({ type: String, required: true })
  private text!: string;

  private copy() {
    navigator.clipboard.writeText(this.text);
  }
}
