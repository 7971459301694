













import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class DotGroup extends Vue {
  @Prop({ default: false, type: Boolean })
  private checked!: boolean;

  @Prop({ default: 0, type: Number })
  private count!: number;

  @Prop({ default: 4, type: Number })
  private limit!: number;
}
